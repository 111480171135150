import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import '@fontsource/montserrat'
import '@fontsource/source-sans-pro'
// LU only! import { currentLang } from '../helpers/i18n'

import Layout from '../components/partials/layout'
import Header from '../components/home/header'
import Text from '../components/home/text'
import Skeeled from '../components/skeeled'
// LU only! import People from '../components/home/people'

class RootIndex extends React.Component {
  render() {
    const cookies = get(this, 'props.data.contentfulCookies')
    const menuItems = get(this, 'props.data.allContentfulMenuItem.nodes')
    const headerVideoUrl = get(this, 'props.data.video.file.url')
    const fallbackMain = get(this, 'props.data.fallback_main')
    const workAt = get(this, 'props.data.workAt')
    const why = get(this, 'props.data.why')
    // LU only!
    // const people = get(this, 'props.data.allContentfulPerson.nodes')

    // let { lang } = currentLang()
    // const urlColleagues = menuItems
    //   .filter((m) => m.node_locale === lang)
    //   .find((m) => m.code === 'colleagues').url

    // Shuffle array
    // const shuffled = people.sort(() => 0.5 - Math.random())
    // Get sub-array of first n elements after shuffled
    // let fourRandomPeople = shuffled.slice(0, 4)

    return (
      <Layout
        location={this.props.location}
        menuItems={menuItems}
        cookies={cookies}
      >
        <Header videoUrl={headerVideoUrl} gatsbyImage={fallbackMain} />
        <Text text={workAt} />
        <Skeeled />
        <Text text={why} />
        {/* LU only! <People peeps={fourRandomPeople} url={urlColleagues} /> */}
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery($locale: String!) {
    contentfulCookies(node_locale: { eq: $locale }) {
      linkName
      linkUrl
      title
      content
    }
    allContentfulMenuItem(sort: { fields: [contentful_id] }) {
      nodes {
        node_locale
        code
        name
        url
      }
    }
    video: contentfulAsset(title: { eq: "video" }) {
      file {
        url
      }
    }
    fallback_main: contentfulAsset(title: { eq: "fallback_main" }) {
      gatsbyImageData(
        layout: FULL_WIDTH
        placeholder: BLURRED
        width: 6789
        height: 4531
      )
    }
    workAt: contentfulTextComponent(
      code: { eq: "work-at" }
      node_locale: { eq: $locale }
    ) {
      code
      blueText
      blackText
      content {
        raw
      }
    }
    why: contentfulTextComponent(
      code: { eq: "why" }
      node_locale: { eq: $locale }
    ) {
      code
      blueText
      blackText
      content {
        raw
      }
    }
    # LU only! allContentfulPerson(
    #   filter: { companies: { in: [$locale] }, node_locale: { eq: $locale } }
    # ) {
    #   nodes {
    #     name
    #     title
    #     shortBio {
    #       shortBio
    #     }
    #     image {
    #       gatsbyImageData(
    #         layout: FULL_WIDTH
    #         placeholder: BLURRED
    #         width: 240
    #         height: 240
    #       )
    #     }
    #   }
    # }
  }
`
